import { jQuery } from './libs/jquery-3.6.0.min.js';
import { intersectionObserver } from './libs/intersection-observer.js';
import { polyfill } from './libs/polyfill.min.js';
import { matchHeight } from './libs/jquery.matchHeight.js';
import { slick } from './libs/slick.min.js';
import { vimeo } from './libs/vimeo.js';
jQuery();
polyfill();
intersectionObserver();
matchHeight();
slick();
vimeo();